.measure-card-container {
    margin: 0 10px;

    &:first-child {
        margin: 10px 10px 0 10px; }

    &:last-child {
        margin: 0 10px 10px 10px; }

    .measure-card {
        margin: 10px; } }

.horizontal-scrollable.row {
    position: relative;
    overflow-x: auto;
    flex-wrap: unset;
    margin-left: 20px;
    margin-right: 20px;
    padding-bottom: 30px;

    > div[class^="col-"] {
        padding-left: 20px;
        padding-right: 0;

        > .measure-card {
            margin: 0;
            height: 100%;

            .measure-card {
                margin: 10px 0; } }

        &:first-child {
            padding-left: 0; } } }


@media screen and (max-width: 767px) {
    .measure-card-container {
        margin: 0; }

    .horizontal-scrollable.row {
        margin: 0;

        > div[class^="col-"] {
            padding-left: 10px; } } }



@media screen and (max-width: 575.98px),
screen and (max-height: 575.98px) {
    .measure-card-container,
    .measure-card-container:first-child,
    .measure-card-container:last-child {
        margin: 0; } }

.measure-card {
    background: #fff;
    box-shadow: 1px 1px 5px 0 rgba(0,0,0,0.1);
    border-radius: 0.5rem;

    padding: 1rem;
    margin: 1rem;
    border-left-color: #fff;

    .measure-card-header {
        min-height: 2rem;

        .measure-card-title {
            font-size: 1.4rem;
            font-weight: 400;
            color: #2d2d2d;
            letter-spacing: 0;
            text-transform: capitalize; }

        .measure-card-subtitle {
            font-size: 1rem;
            font-weight: 300;
            color: gray;
            text-transform: lowercase; }

        .measure-card-separator {
            display: block;
            margin: 0.5rem 0;

            hr {
                margin: 0; } }

        .measure-card-icon {
            margin: 0 0.3rem 0.3rem 0; } }

    .measure-card-body {
        & > div, p {
            color: #2d2d2d;
            letter-spacing: 0;
            font-weight: 300; }

        .measure-card {
            background-color: #FDFDFD;
            box-shadow: inset 0 0 10px 0 rgba(0,0,0,0.1); }

        & > .measure-card-container {
            margin: 0; }

        & > .measure-card {
            padding: 0.5rem;
            margin: 0;

            .measure-card-header {
                .measure-card-title {
                    font-size: 1rem;
                    font-weight: 500;
                    line-height: 2rem;
                    margin-bottom: 0.5rem; } }

            .measure-card-separator {
                display: none; } }

        table {
            margin: 0 auto;
            text-align: center;
            width: 100%;

            td, th {
                padding: 0.5rem 1rem; }

            thead {
                border-bottom: 1px solid rgba(0, 0, 0, .1);

                th {
                    font-weight: 300;
                    color: #2f2f2f; } } } } }


@media screen and (max-width: 767px) {
    .measure-card {
        padding: 0.5rem;
        margin: 0.3rem !important;
        // border-radius: 0

        .measure-card-header {
            .measure-card-title {
                font-size: 1.3rem; }

            .measure-card-subtitle {
                font-size: 1rem; } } } }


@keyframes pulse-attention {
    100% {
        background: darken(white, 10%); } }

@keyframes pulse-error {
    100% {
        background: #dc3545; } }

@keyframes pulse-error-color {
    100% {
        border-top: 1px solid white; } }

@keyframes pulse-error-text {
    100% {
        color: white; } }

@keyframes pulse-warning {
    100% {
        background: #FF8800; } }

@keyframes pulse-warning-color {
    100% {
        border-top: 1px solid white; } }

@keyframes pulse-warning-text {
    100% {
        color: white; } }

.card-highlight {
    border-left: 0.5rem solid #0d4988;
    padding-left: 0.5rem !important;

    &.card-attention-active {
        $animation-duration: 1s;
        border-left-color: rgba(0, 0, 0, 0);
        animation: $animation-duration ease-in infinite alternate pulse-attention; }

    &.card-error {
        border-left-color: #dc3545 !important;
        background: #dc3545;

        .measure-card-header {
            .measure-card-title,
            .measure-card-subtitle {
                color: white; } }

        .measure-card-separator hr {
            border-top: 1px solid white; }

        .measure-card-body {
            &, * {
                color: white; } } }

    &.card-error-active {
        $animation-duration: 1s;
        border-left-color: rgba(0, 0, 0, 0);
        animation: $animation-duration ease infinite alternate pulse-error;

        .measure-card-header {
            .measure-card-title,
            .measure-card-subtitle {
                animation: $animation-duration ease infinite alternate pulse-error-text; } }

        .measure-card-separator hr {
            animation: $animation-duration ease infinite alternate pulse-error-color; }

        .measure-card-body {
            &, * {
                animation: $animation-duration ease infinite alternate pulse-error-text; } } }

    &.card-warning {
        border-left-color: #FF8800 !important;
        background: #FF8800;

        .measure-card-header {
            .measure-card-icon,
            .measure-card-title,
            .measure-card-subtitle {
                color: white; } }

        .measure-card-separator hr {
            border-top: 1px solid white; }

        .measure-card-body {
            &, * {
                color: white; } } }

    &.card-warning-active {
        $animation-duration: 1s;
        border-left-color: rgba(0, 0, 0, 0);
        animation: $animation-duration ease infinite alternate pulse-warning;

        .measure-card-header {
            .measure-card-icon,
            .measure-card-title,
            .measure-card-subtitle {
                animation: $animation-duration ease infinite alternate pulse-warning-text; } }

        .measure-card-separator hr {
            animation: $animation-duration ease infinite alternate pulse-warning-color; }

        .measure-card-body {
            &, * {
                animation: $animation-duration ease infinite alternate pulse-warning-text; } } }

    &.card-quarantined {
        border-left-color: #728C69 !important;

        .measure-card-header {
            .measure-card-icon {
                color: #728C69;

                .icon-text {
                    font-size: 0.7rem;
                    text-transform: uppercase;
                    vertical-align: middle; } } } }

    &.card-disabled {
        border-left-color: #C2C2C2 !important;

        .measure-card-header {
            .measure-card-icon {
                color: #C2C2C2;

                .icon-text {
                    font-size: 0.7rem;
                    text-transform: uppercase;
                    vertical-align: middle; } } } } }






