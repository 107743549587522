$mdataset_time_span_height: 2rem;


.mdataset-time-span {
    margin: $mdataset_time_span_height * 0.25 $mdataset_time_span_height * 0.25 $mdataset_time_span_height * 0.25 0;

    .mdataset-time-span-button {
        height: $mdataset_time_span_height;
        min-width: $mdataset_time_span_height * 3;
        background-color: var(--measure_background_color);
        border: $mdataset_time_span_height * 0.04 solid var(--measure_primary);
        font-size: $mdataset_time_span_height * 0.4;
        font-weight: bolder;
        color: var(--measure_primary);
        outline: none;

        &:active, &.active {
            background-color: var(--measure_primary);
            color: var(--measure_background_color);
        }

        &.first {
            border-bottom-left-radius: $mdataset_time_span_height * 0.15;
            border-top-left-radius: $mdataset_time_span_height * 0.15;
        }

        &.last {
            border-bottom-right-radius: $mdataset_time_span_height * 0.15;
            border-top-right-radius: $mdataset_time_span_height * 0.15;
            border-left: none;
        }
    }

    .mdataset-time-clear-button {
        height: $mdataset_time_span_height;
        width: $mdataset_time_span_height;
        background-color: #cc0000;
        border: $mdataset_time_span_height * 0.04 solid #cc0000;
        color: white;
        border-radius: $mdataset_time_span_height * 0.15;
        margin-left: $mdataset_time_span_height * 0.25;
    }
}
