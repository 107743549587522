.not-found-img {
    max-height: 40vh; }

.text-container {
    top: 5vh;
    left: 15px;
    width: 100vw;
    text-align: center; }

@media (min-width: 768px) {
    .text-container {
        height: 40vh;
        position: absolute;
        top: 5vh;
        right: 30vw; } }

.middle-text .title {
    font-size: 2rem;
    font-weight: 700; }

.middle-text .subtitle {
    font-size: 1.5rem;
    color: #004580; }

.not-found-message p {
    font-size: 1.5rem; }

.not-found-message-small p {
    font-size: 1.2rem;

    strong {
        color: #0D4989; } }

.not-found-button-container {
    display: flex;
    width: 100%;

    justify-content: center;
    align-content: center; }

.not-found-button {
    flex: 1;
    margin: 0 30px;
    max-width: 30%; }

@keyframes fadeIn {
    0% {
        opacity: 0; }
    100% {
        opacity: 1; } }

.links-container {
    animation: fadeIn ease 10s; }
