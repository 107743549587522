.json-wrapper {
    position: relative;

    .expand-button {
        position: absolute;
        top: 29px;
        left: 0;
        width: 30px;
        height: 25px;
        border: 1px solid rgba(118, 118, 118, 0.3);
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        background-color: #0d4988;
        color: white;

        svg {
            display: flex;
            align-items: center;
        }
    }

    button:focus {
        outline: none;
    }

    .copy-button {
        position: absolute;
        top: 0;
        left: 0;
        width: 30px;
        height: 25px;
        border: 1px solid rgba(118, 118, 118, 0.3);
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        background-color: #0d4988;
        color: white;
        line-height: 25px;

        svg {
            display: flex;
            align-items: center;
        }
    }

    .copy-button-single-row {
        height: 30px;
        line-height: 30px;
    }

    .clipboard-text {
        position: absolute;
        top: 0;
        left: 30px;
        height: 30px;
        line-height: 20px;
        padding: 5px 10px;
        background-color: black;
        color: white !important;
        border-top-left-radius: 0;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        font-size: 12px;
        font-weight: 300;
    }
}

.json-container {
    width: 93%;
    min-height: 30px;
    line-height: 24px;
    resize: none;
    margin: 0 0 0.5rem 30px;
    padding: 2px 2px 2px 10px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.json-container-highlight {
    background-color: #0d4988;
    color: white;
}
    