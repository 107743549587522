.recharts-cartesian-axis-ticks {
    user-select: none;

    text {
        fill: var(--measure_font_color);
    }
}

.mchart-tooltip {
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 1px 1px 10px 0 rgba(0,0,0,0.1);
    background-color: var(--measure_background_color);

    .mchart-tooltip-body {
        .label {
            color: var(--measure_background_color);
            font-weight: 300;
            padding: 0.2rem 0.5rem;
            margin: 0;
            background-color: var(--measure_primary);
        }
        .value {
            padding: 0.5rem;
            margin: 0;

            .circle {
    
            }
    
            .text {
    
            }
        }
    }
}