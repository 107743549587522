

@keyframes AnimatedBackground {
    0% {
        transform: translateY(0); }
    15% {
        transform: translateY(-5px); }
    85% {
        transform: translateY(-5px); }
    100% {
        transform: translateY(0); } }

.measure-slide-bar-container {
    width: 100%;
    .measure-slide-bar {
        margin: auto;
        background: linear-gradient(0deg, lighten(#0d4989, 30%), #2d2d2d);
        background-size: 200% 200%;
        height: 0.4rem;
        border-radius: 0.2rem;
        animation: AnimatedBackground 1.2s ease-in infinite; }

    .measure-slide-bar-text {
        color: #0d4989;
        font-weight: 300;
        margin-bottom: 1rem; } }
