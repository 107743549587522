$mside_menu_mobile_header_height: 3rem;

.mside-menu {
    max-height: 100vh;
    overflow-y: scroll;

    background-color: var(--measure_background_color);
    box-shadow: 1px 1px 5px 0 rgba(0,0,0,0.1);

    .mside-menu-mobile-header {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
    
        height: $mside_menu_mobile_header_height;
        padding: $mside_menu_mobile_header_height * 0.15;
        background-color: var(--measure_background_color);
        border-bottom: 1px solid var(--measure_background_color_secondary);
        box-shadow: 1px 1px 5px 0 rgba(0,0,0,0.1);
        z-index: 30;
    
        .mside-menu-mobile-button {
            float: right;
            width: $mside_menu_mobile_header_height * 0.5;
            height: $mside_menu_mobile_header_height * 0.5;
            margin: $mside_menu_mobile_header_height * 0.1;
            padding: 0;
    
            background-color: transparent;
            border: none;
            outline: none;
    
            > span {
                display: block;
                border-bottom: $mside_menu_mobile_header_height * 0.03 solid var(--measure_primary);
    
                &:first-child {
                    margin-top: $mside_menu_mobile_header_height * 0.1;
                }
    
                &:nth-child(2) {
                    margin-top: $mside_menu_mobile_header_height * 0.15;
                    margin-bottom: $mside_menu_mobile_header_height * 0.15;
                }
    
                &:last-child {
                    margin-bottom: $mside_menu_mobile_header_height * 0.1;
                }
            }
    
            &.active {
                > span {
                    &:first-child {
                        margin: 0;
                        transform: rotate(45deg);
                    }
                    &:nth-child(2) {
                        display: none;
                    }
                    &:last-child {
                        margin: 0;
                        transform: translate(0, -$mside_menu_mobile_header_height * 0.02) rotate(-45deg);
                    }
                }
            }
        }
    }

    .mside-menu-body {
        padding: $mside_menu_mobile_header_height * 0.25;
        background-color: var(--measure_background_color);

        .mside-menu-body-logo {
            text-align: center;
            margin-bottom: $mside_menu_mobile_header_height * 0.25;
        }

        .mside-menu-body-content {
            font-size: 1rem;
            color: var(--measure_font_color);

            .mside-menu-body-list {
                list-style-type: none;
                margin: 0;
                padding: 0;

                > li, > li > a {
                    padding: $mside_menu_mobile_header_height * 0.10 0 $mside_menu_mobile_header_height * 0.10 $mside_menu_mobile_header_height * 0.25;
                    font-weight: 300;
                }
                
                .mside-menu-body-list-title {
                    font-weight: 600;
                    padding-left: 0;
                    margin-top: $mside_menu_mobile_header_height * 0.25;
                    color: var(--measure_primary);
                }

                .mside-menu-body-list-element > a {
                    color: var(--measure_font_color)!important;
                    padding: $mside_menu_mobile_header_height * 0.10 0;
                }

                .mside-menu-body-list-element > a:hover {
                    text-decoration: none !important;
                }

                > li:first-child {
                    margin-top: 0;
                }
            }
        }
    }
}

@media screen and (min-width: 767px) {
    .mside-menu {
        height: 100vh;
        overflow-y: scroll;

        .mside-menu-mobile-header {
            display: none;
        }
    }
}

@media screen and (max-width: 766px) {
    .mside-menu {
        margin-top: $mside_menu_mobile_header_height;
        max-height: 0;

        .mside-menu-body-logo {
            display: none;
        }
    
        .mside-menu-body {
            position: fixed;
            top: $mside_menu_mobile_header_height;
            left: 0;
            right: 0;
            z-index: 2;
            min-height: 0;
            z-index: 2;

            max-height: 90vh;
            overflow-y: scroll;

            box-shadow: 5px 5px 5px 0 rgba(0,0,0,0.1);
        }
    }
}
