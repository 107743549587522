.section-container {
    padding: 0 !important; }

.section {
    min-height: 80vh;
    overflow: hidden;

    &>div {
        padding: 4rem; }

    .section-title {
        font-size: 2rem;
        font-weight: 500; }

    .section-paragraph {
        color: #0D4989;
        font-size: 1.5rem;
        font-weight: 250;
        text-align: right;
        margin-bottom: 2rem;

        &:last-child {
            margin-bottom: 0; } } }

.section-auto {
    min-height: auto !important; }

.section-expand {
    &>div {
        padding: 0 !important; } }

@media screen and (max-width: 767px) {
    .section {
        &>div {
            padding: 1rem; }

        .section-title {
            font-size: 1.5rem;
            text-align: center; }

        .section-paragraph {
            font-size: 1.2rem;
            text-align: center; } } }


