.metric-value-container {
    position: absolute;
    top: 4rem;
    bottom: 0;
    left: 0;
    right: 0; }

.metric-value {
    text-align: center;
    overflow: hidden;
    font-weight: 400;
    font-family: Helvetica;
    margin: 0;

    .metric-unit {
        font-weight: 500; } }
