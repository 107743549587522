.img-section {
    height: 100vh;
    text-align: center;
    background-color: white;

    img {
        pointer-events: none; }

    img.stage-image {
        position: absolute;
        max-height: 95vh;
        max-width: 95vw;
        width: auto;
        height: auto;
        left: 10vw;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%); }

    &>.image-title {
        pointer-events: none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 15vw;
        width: 70vw;
        font-size: 4.5rem;
        color: white; }

    @media screen and (max-width: 767px) {
        &>.image-title {
            left: 10vw;
            width: 80vw;
            font-size: 2.1rem; } } }

