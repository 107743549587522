.dig-section {
    background-color: #fafafa; }

.title-container {
    margin: 30vh 0 0 0; }

.image-container {
    text-align: center; }

.image-container img {
    max-width: 45vw;
    max-height: 90vh; }

@media screen and (max-width: 767px) {
    .image-container img {
        max-width: 100%; }

    .title-container {
        margin: 0; } }
