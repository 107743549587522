.mform-field-wrapper {
    position: relative;

    input, textarea {
        display: inline-block;
        width: 90%;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
    }

    textarea+button {
        height: 92% !important;
    }

    select+button {
        display: none !important;
    }

    button {
        position: absolute;
        right: 0px;
        top: 0px;
        height: 100%;
        width: 10%;
        border: 1px solid #ced4da;
        border-left: none;
        border-top-right-radius: .25rem;
        border-bottom-right-radius: .25rem;
        font-size: 1rem;
        background-color: var(--measure_primary);
        color: var(--measure_background_color);

        &:hover, &:active {
            background-color: var(--measure_selected);
        }
    }

    .is-invalid+button {
        border-color: #dc3545;
    }
}