$mbutton_height: 2rem;

.mbutton {
    height: $mbutton_height;

    margin: $mbutton_height * 0.0625 0 $mbutton_height * 0.0625 $mbutton_height * 0.0625;
    background-color: var(--measure_background_color);
    border: $mbutton_height * 0.02 solid var(--measure_primary);
    border-radius: $mbutton_height * 0.075;
    font-size: $mbutton_height * 0.4;
    color: var(--measure_primary);
    outline: none;

    &:active, &:hover {
        background-color: var(--measure_primary);
        color: var(--measure_background_color);
    }
}