.mcards-horizontal-container {
    &.row {
        position: relative;
        overflow-x: auto;
        flex-wrap: unset;
        margin-left: 0;
        margin-right: 0;
        cursor: grab;
    
        > div[class^="col-"] {
            padding-left: 20px;
            padding-right: 0;
    
            > .mcard-card {
                margin: 0;
                height: 100%;
                overflow-y: scroll;
    
                padding: 0;
                border-top: 0.5rem solid var(--measure_background_color);
                border-bottom: 0.5rem solid var(--measure_background_color);
                border-left: 1rem solid var(--measure_background_color);
                border-right: 1rem solid var(--measure_background_color);

                .mcard-card {
                    margin: 10px 0;
                }
            }
            
            &:first-child {
                padding-left: 0;
            }
        }
    }
}