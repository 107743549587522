@font-face {
    font-family: 'MeasureRegular';
    src: url('../fonts/Roboto-Regular.ttf');
}

:root {
    --measure_primary: #0D4989;
    --measure_secondary: grey;
    --measure_selected: #4BD763;
    --measure_font_color: #2d2d2d;
    --measure_background_color: #ffffff;
    --measure_background_color_secondary: #fdfdfd;

    --measure_font_regular: 'MeasureRegular';
}

// Triggered by: document.documentElement.setAttribute('data-theme', 'dark');

[data-theme="dark"] {
    --measure_primary: #ffffff;
    --measure_secondary: #999999;
    --measure_selected: #4BD763;
    --measure_font_color: #ffffff;
    --measure_background_color: #2d2d2d;
    --measure_background_color_secondary: #444444;

    --measure_font_regular: 'MeasureRegular';
}

html {
  font-size: 100%; // 100% = browser config
}

body {
    font-family: var(--measure_font_regular), "Helvetica Neue", Helvetica, Arial, sans-serif;
    background-color: var(--measure_background_color_secondary);
}
