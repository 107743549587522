.mdataset-selector {
    padding-left:0.5rem;

    .mdataset-buttons {
        overflow: scroll;

        button {
            width: 100%;
            color: white;
            border: 1px solid transparent;
            margin-top: 2px;
            background-color: var(--measure_secondary);
            padding: 0.3rem 0;

            &:first-of-type {
                margin-top: 0;
                border-top-left-radius: 0.5rem;
                border-top-right-radius: 0.5rem;
            }

            &:last-child {
                border-bottom-left-radius: 0.5rem;
                border-bottom-right-radius: 0.5rem;
            }

            &:hover, &.active {
                background-color: var(--measure_selected);
            }
        }
    }

    @media screen and (max-width: 767px) {
        padding: 0;
    }
}

.share-button {
    margin-bottom: 0.5rem;
}
