$mcard_header_height: 4rem;

.mcard-card {
    background-color: var(--measure_background_color);
    box-shadow: 1px 1px 5px 0 rgba(0,0,0,0.1);
    border-radius: $mcard_header_height * 0.075;
    padding: $mcard_header_height * 0.125 $mcard_header_height * 0.25;

    .mcard-header {
        height: $mcard_header_height * 0.5;
        line-height: $mcard_header_height * 0.5;

        .mcard-header-icon {
            margin-left: $mcard_header_height * 0.125;
            height: $mcard_header_height * 0.25;
            min-width: $mcard_header_height * 0.25;
            font-size: $mcard_header_height * 0.25;
            color: var(--measure_font_color);
        }
            
        .mcard-header-title {
            margin-left: $mcard_header_height * 0.125;
            font-size: $mcard_header_height * 0.25;
            color: var(--measure_font_color);
        }

        .mcard-header-subtitle {
            margin-left: $mcard_header_height * 0.125;
            font-size: $mcard_header_height * 0.175;
            color: var(--measure_secondary);
        }

        .mcard-header-button {
            height: $mcard_header_height * 0.375;
            min-width: $mcard_header_height * 0.375;
            float: right;
            margin: $mcard_header_height * 0.0625 0 $mcard_header_height * 0.0625 $mcard_header_height * 0.0625;
            background-color: var(--measure_background_color);
            border: $mcard_header_height * 0.02 solid var(--measure_primary);
            border-radius: $mcard_header_height * 0.075;
            font-size: $mcard_header_height * 0.175;
            color: var(--measure_primary);
            outline: none;
            line-height: $mcard_header_height * 0.0075;

            &:active {
                background-color: var(--measure_primary);
                color: var(--measure_background_color);
            }
        }

        .mcard-header-button-active {
            background-color: var(--measure_primary);
            color: var(--measure_background_color);
        }

        >:first-child {
            margin-left: 0 !important;
        }

        .mcard-header-button ~ .mcard-header-button {
            margin: $mcard_header_height * 0.0625;
        }
    }

    .mcard-separator {
        margin: $mcard_header_height * 0.125 0;
        border-bottom: $mcard_header_height * 0.02 solid var(--measure_primary);
    }

    .mcard-body {
        color: var(--measure_font_color);
        font-size: $mcard_header_height * 0.2;

        .mcard-empty-state {
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;

            .mcard-empty-state-title {
                font-size: $mcard_header_height * 0.35;
                margin-bottom: $mcard_header_height * 0.3;
            }

            .mcard-empty-state-icon {
                font-size: $mcard_header_height * 0.75;
                margin-bottom: $mcard_header_height * 0.3;
            }

            .mcard-empty-state-subtitle {
                font-size: $mcard_header_height * 0.2;
                color: var(--measure_secondary);
            }
        }
    }

    .mcard-card {
        margin: $mcard_header_height * 0.125 0;
        background-color: var(--measure_background_color_secondary);
        box-shadow: inset 0 0 10px 0 rgba(0,0,0,0.1);

        .mcard-header {
            height: $mcard_header_height * 0.3;
            line-height: $mcard_header_height * 0.3;

            .mcard-header-icon {
                margin-left: $mcard_header_height * 0.125;
                height: $mcard_header_height * 0.20;
                min-width: $mcard_header_height * 0.20;
                font-size: $mcard_header_height * 0.20;
                color: var(--measure_font_color);
            }
                
            .mcard-header-title {
                margin-left: $mcard_header_height * 0.125;
                font-size: $mcard_header_height * 0.20;
                color: var(--measure_font_color);
            }
    
            .mcard-header-subtitle {
                margin-left: $mcard_header_height * 0.125;
                font-size: $mcard_header_height * 0.17;
                color: var(--measure_secondary);
            }

            .mcard-header-button {
                height: $mcard_header_height * 0.375;
                min-width: $mcard_header_height * 0.375;
                margin: -($mcard_header_height * 0.0375) 0 0 $mcard_header_height * 0.075;

                &:first-child {
                    margin: -($mcard_header_height * 0.0375) 0 0 0;
                }
            }
        }
    }

    &.active {
        border-left: 5px solid var(--measure_selected);
    }

    table {
        margin-top: $mcard_header_height * 0.25;
        text-align: center;
        width: 100%;
        color: var(--measure_font_color);

        td, th {
            padding: $mcard_header_height * 0.125 0;
        }

        thead {
            border-bottom: 1px solid var(--measure_primary);

            th  {
                font-weight: 600;
                color: var(--measure_font_color);
            }
        }

        tbody {
            tr:nth-of-type(odd) {
                background-color: var(--measure_background_color);
            }

            tr:nth-of-type(even) {
                background-color: var(--measure_background_color_secondary);
            }
        }
    }
}