.mhubs-page-center-container {
    color: var(--measure_font_color);
    margin-top: 40vh;

    .mhubs-page-container-icon {
        height: 2rem;
    }

    .mhubs-page-container-title {
        font-size: 2rem;
        font-style: italic;
        font-weight: 300;
    }

    .mhubs-page-container-subtitle {
        font-size: 1rem;
        font-weight: 300;
    }
}