
.contact-section {
    background-color: #2d2d2d;
    padding: 1rem;

    .section-title {
        color: white; }

    .success-message {
        h4, h1 {
            color: white !important; } }

    .form-text, .text-muted {
        color: white !important; } }

.contact-link {
    font-size: 1.5rem;
    color: white !important;
    text-decoration: none;
    margin: 0 0.5rem; }

.contact-link:hover,
.contact-link:active {
    color: gray !important; }
