.radio-group-label {
    color: var(--measure_font_color);
    margin: 0.5rem 0;
    font-weight: 600;
}

.radio-group {
    display: inline-block;
    margin-bottom: 0.5rem;
    border-radius: 10px;
    overflow: hidden;

    label {
        color: #fff;
        background-color: var(--measure_secondary);
        display: inline-block;
        cursor: pointer;
        padding: 5px 20px;
        margin: 0;
        margin-right: 2px;

        input[type=radio] {
            position: absolute;
            visibility: hidden;
            display: none;
        }

        &.checked {
            background: var(--measure_selected);
        }

        @media screen and (max-width: 767px) {
            padding: 5px 10px;
        }
    }

    label:last-child {
        margin-right: 0;
    }
}