$navbar-max-height: 10vh;
$navbar-max-height-xs: 10vh;
$navbar-padding: 2vh;
$navbar-inner-height: $navbar-max-height - 2 * $navbar-padding;
$navbar-inner-height-xs: $navbar-max-height-xs - 2 * $navbar-padding;

.mea-navbar {
    max-height: $navbar-max-height;
    padding: $navbar-padding;

    img {
        pointer-events: none;
        height: $navbar-inner-height !important;
        min-height: 3rem; }

    .mea-navbar-button {
        line-height: $navbar-inner-height !important;
        font-size: 1.2rem; } }

@media screen and (max-width: 767px) {
    .mea-hidden-xs {
        display: none; }

    .mea-navbar {
        max-height: $navbar-max-height-xs;
        padding: 2vh;

        img {
            height: $navbar-inner-height-xs !important; }

        .mea-navbar-button {
            height: $navbar-inner-height-xs;
            width: $navbar-inner-height-xs;
            padding: 0;

            i {
                position: relative;
                top: 0.2rem;
                font-size: 2rem; } } } }
