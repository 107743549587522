.hour-display {
    padding-top: 3.5rem;
    font-size: 5.5rem;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    color: var(--measure-font-color);

    small {
        text-transform: uppercase;
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 767px) {
    .hour-display {
        font-size: 3.5rem;
    }
}
