$wallboard-background: #100937;

$logo-height: 8vh;
$logo-vertical-padding: 2vh;

$rows-amount: 2;
$row-height: (96vh - $logo-height) / $rows-amount;

.measure-logo {
    height: $logo-height; }

.wallboard-container {
    padding: 2vh;
    background-color: $wallboard-background; }

// 100937
// 0D4988
// FOnt E0E1E5

.wallboard-row {
    padding: 1rem 0 0 0;

    &:first-child {
        padding: 0; }

    &>div {
        margin: 0 0 0 1rem;

        &:first-child {
            margin: 0; }

        .measure-card {
            height: 100%;
            margin: 0; } } }

.row-half {
    height: $row-height; }
