$section-height: 80vh;

.first-section {
    min-height: $section-height;

    .first-section-title-col {
        height: 30vh;

        .landing-title {
            font-size: 2.5rem;
            font-weight: 700;
            position: absolute;
            bottom: 0;
            left: 15px;
            right: 15px; } }

    .first-section-subtitle-col {
        height: 20vh;

        .landing-subtitle {
            color: #0D4989;
            font-size: 1.5rem;
            font-weight: 200; } }

    .first-section-image-col {
        display: block;
        width: 100%;
        position: relative;
        height: 50vh;
        padding-top: 45.45%;
        overflow: hidden;
        text-align: center;

        .first-section-image {
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%);
            max-width: 100%;
            max-height: 100%;
            pointer-events: none; } } }

@media screen and (max-width: 767px) {
    .first-section {
        .first-section-title-col {
            .landing-title {
                text-align: center;
                font-size: 2rem; } }

        .first-section-subtitle-col {
            .landing-subtitle {
                text-align: center;
                font-size: 1.3rem; } } } }
